define("discourse/plugins/swapd-customcomposerfields/discourse/templates/components/custom-composer-fields", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each model.categoryCustomComposerFields as |field|}}
    {{ccf-field
      value=(mut (get model.custom_composer_fields.values field.name))
      field=field
    }}
  {{/each}}
  */
  {
    "id": "AVmJVK3a",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\",\"categoryCustomComposerFields\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"value\",\"field\"],[[28,[37,3],[[28,[37,4],[[30,0,[\"model\",\"custom_composer_fields\",\"values\"]],[30,1,[\"name\"]]],null]],null],[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/swapd-customcomposerfields/discourse/templates/components/custom-composer-fields.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/swapd-customcomposerfields/discourse/templates/components/custom-composer-fields.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"field\"],false,[\"each\",\"-track-array\",\"ccf-field\",\"mut\",\"get\"]]",
    "moduleName": "discourse/plugins/swapd-customcomposerfields/discourse/templates/components/custom-composer-fields.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});